<template>
  <div class="guide-cnt9">
    <h6 class="h6">定制需知</h6>
    <div class="cnt">
      <p class="p ps">定制类产品由于不能进行二次销售，所以定稿以后不接受退换货，定制产品一经生产不支持修改内容。经双方核对后，稿件一致就不属于售后问题，生产后若发现图文错误，责任由顾客承担，敬请知悉！</p>
      <p class="p">关于印刷品色差：</p>
      <p class="p">CMYK印刷品与RGB电脑显示的色彩显示原理不同，业内认为10%之内的色差技术在允许范围，对色差十分在意的朋友请考虑清楚之后再买，色差不能作为中差评、投诉、退换货的依据，颜色以实际的货品为准，敬请谅解。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt9',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt9 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-bottom:10px;
    }
    .ps {
      margin-bottom:30px;
    }
  }
}
</style>
